import {useEffect, useState} from "react"
import {Card, Col, Container, Row, Table} from "react-bootstrap"
import {Brush, ComposedChart, Line, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts"
import '../chart.css'
import {CardSection} from "../common/Card"
import {DataClient, DateRange, PowerEntry} from "../../client/DataClient"
import {EventScheduleClient, EventWithDetails} from "../../client/EventScheduleClient"
import {BuildingClient, Tariff} from "../../client/BuildingClient"
import {Building} from "../BuildingStatus"
import {DateTime} from "../../time/DateTime"
import {DatePicker} from "@mui/x-date-pickers"
import {Select, SelectOption} from "../common/MuiSelect"
import {CrudTable} from "../common/CrudTable"
import {AsyncComponent} from "../common/AsyncComponent"
import {addProgramme, createTariff, editControlStrategy, editTariff, meteringOptionalRoute} from "../../Routes"
import {useNavigate, useParams} from "react-router-dom"
import {isSuccess} from "../../client/Result";

export interface ConsumptionPageProps {
    buildingClient: BuildingClient
    dataClient: DataClient
    eventClient: EventScheduleClient
}

export const ConsumptionPage = ({ buildingClient, dataClient, eventClient }: ConsumptionPageProps) => {

    const { buildingId }  = useParams()

    const now: string = DateTime.local().toISODate()!
    const [date, setDate] = useState<string>(now)
    const [buildings, setBuildings] = useState<Building[]>([])
    const [events, setEvents] = useState<EventWithDetails[]>([])
    const [selectedBuilding, setSelectedBuilding] = useState<Building>()
    const [readings, setReadings] = useState<PowerEntry[]>()
    const [brushRange, setBrushRange] = useState<[number, number]>()
    const [refreshTrigger, setRefreshTrigger] = useState<number>(0)

    const isToday = () => now == date

    const navigate = useNavigate()

    useEffect(() => {
        const runEffect = async () => {
            const retrievedBuildings = await buildingClient.retrieveBuildings()

            if (buildingId) {
                setSelectedBuilding(retrievedBuildings.find(x => x.id == Number(buildingId)))
            } 

            await setBuildings(retrievedBuildings)
            
        }
        runEffect()
    }, [])


    useEffect(() => {
        if (selectedBuilding && buildingId && selectedBuilding.id !=  Number(buildingId)) {
            setSelectedBuilding(buildings.find(x => x.id == Number(buildingId)))
        }
    }, [buildingId])


    useEffect(() => {
        setReadings(readings)
    }, [refreshTrigger])

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedBuilding) return

            const start = DateTime.fromISO(date)

            const range = new DateRange(
                start,
                start.endOf('day')
            )
            const transformedReadings = await dataClient.powers(selectedBuilding.id, range)

            setReadings(transformedReadings)

            const events = (await eventClient.getEvents(range.start, range.end.plus({days: 1}), [selectedBuilding.id]))
                .filter(notUnavailability)
                .filter(isEventDetails)

            setEvents(
                events.filter(e =>
                    !e.eventStatus.cancelledNeverSentToDevice() && !e.eventStatus.cancelledAndSuccessfullyRemovedFromDevice()
                ))

            if (isToday() && transformedReadings.length > 12 * 2) {
                setBrushRange([transformedReadings.length - 12 * 2, transformedReadings.length - 1])
            } else {
                setBrushRange([0, transformedReadings.length - 1])
            }

        }

        fetchData()
    }, [selectedBuilding, date])

    const isEventDetails = (event: EventWithDetails) : event is EventWithDetails  => event.eventStatus !== undefined
    const notUnavailability = (event: EventWithDetails) : event is EventWithDetails => event.eventType.type !== 'unavailability'

    const loadTariffs = (id: number) => async ()=> {
        const maybeTariffs = await buildingClient.retrieveTariffs(id)
        const tarrifs: Tariff[] = maybeTariffs.fold(t => t, () => [])

        return tarrifs.map(t => ({...t, queryableDataId: t.id.toString(), id: tarrifs.indexOf(t), startDate: t.startDate.toISODate(), endDate: t.endDate.toISODate()}))
    }

    const loadProgrammes = (id: number) => async () => {
        const buildingProgrammes = await buildingClient.retrieveBuildingProgrammes(id);
        if (isSuccess(buildingProgrammes)) {
            return buildingProgrammes.value.map(
                (value, index) => { return {queryableDataId: index, name: value.name }}
            )
        } else {
            return []
        }
    }

    const loadControlStrategy = (id: number) => async () => {
        const controlStrategyResult = await buildingClient.retrieveBuildingStrategy(id);
        if (isSuccess(controlStrategyResult)) {
            return [{...controlStrategyResult.value, queryableDataId: controlStrategyResult.value.id, id: 0}]
        } else {
            return []
        }
    }

    const chartRange = () =>
        readings && readings.length > 0 ? new DateRange(DateTime.fromSeconds(readings[0].date).toLondonTimeZone(), DateTime.fromSeconds(readings[readings.length - 1].date).toLondonTimeZone()) : undefined


    const dateChange = (e) => {
        setDate(e)
        unsetChart()
    }

    const selectBuilding = (e: SelectOption) => {
        const building: Building | undefined = buildings.find(b => `${b.id}` == e.id)

        if (building) {
            unsetChart()
            setSelectedBuilding(building)
            navigate(meteringOptionalRoute.withParams(building.id))
        }
    }

    const unsetChart = () => {
        setBrushRange(undefined)
        setReadings(undefined)
        setEvents([])
    }

    function navigateToEditTariff(tariffId: number): Promise<void> {
        return Promise.resolve(selectedBuilding ? navigate(editTariff.withParams([selectedBuilding.id, tariffId])) : undefined)
    }
    function navigateToEditControlStrategy(controlStrategyId: number): Promise<void> {
        return Promise.resolve(selectedBuilding ? navigate(editControlStrategy.withParams([selectedBuilding.id, controlStrategyId])) : undefined)
    }

    const crudTable =
        selectedBuilding ? <><AsyncComponent loaderFunction={loadTariffs(selectedBuilding.id)}
                                             dataPropName="initialData" effectDependency={selectedBuilding.id} >
            <CrudTable initialData={[]}
                       title={'Tariffs'}
                       editRowById={tariff => navigateToEditTariff(Number(tariff))}
                       create={() => navigate(createTariff.withParams(selectedBuilding.id))}
                       testId="tariffTable"
            /></AsyncComponent>
            <AsyncComponent loaderFunction={loadProgrammes(selectedBuilding.id)} dataPropName="initialData" effectDependency={selectedBuilding.id}>
                <CrudTable initialData={[]}
                           title={'Programmes'}
                           testId="programmeTable"
                           create={() => navigate(addProgramme.withParams(selectedBuilding.id))}
                />
            </AsyncComponent>
            <AsyncComponent loaderFunction={loadControlStrategy(selectedBuilding.id)} dataPropName="initialData"
                            effectDependency={selectedBuilding.id}>
                <CrudTable initialData={[]}
                           title={'Control Strategy'}
                           testId="strategyTable"
                           editRowById={controlStrategy => navigateToEditControlStrategy(Number(controlStrategy))}
                /></AsyncComponent>
        </> : <></>

    return <Container fluid >
        {buildings && <CardSection header={'Building Consumption & Events'}>
            <Row className={'mb-3'}>
                <Col xs={6}>
                    <Select label={'Building'} data-testid={`select-building`} initialValue={selectedBuilding ? { id: `${selectedBuilding.id}`, label: selectedBuilding.name } : selectedBuilding}
                        onChange={selectBuilding}
                        options={buildings.map(it => ({ id: `${it.id}`, label: it.name }))} />
                </Col>
                <Col xs={6}>
                    <DatePicker value={DateTime.fromISO(date).raw()} label={'Date'} onChange={dateChange} disableFuture={true} />
                </Col>
            </Row>
            {selectedBuilding && readings && (readings.length == 0) && <NoDataAvailable />}
            {selectedBuilding && readings && (readings.length > 0) && brushRange && (<ReadingsChart brushRange={brushRange} chartRange={chartRange} events={events} readings={readings} refreshTrigger={refreshTrigger} setBrushRange={setBrushRange} />)
            }
        </CardSection>}
        {selectedBuilding && events.length > 0 && <EventTable chartRange={chartRange} events={events} readings={readings} refreshTrigger={refreshTrigger} setBrushRange={setBrushRange} setRefreshTrigger={setRefreshTrigger} />}
        {crudTable}
    </Container>
}
interface EventTableProps {
    readings, events, setBrushRange, setRefreshTrigger, refreshTrigger, chartRange
}

interface ReadingsChartProps {
    readings, events, setBrushRange, brushRange, refreshTrigger, chartRange
}


const ReadingsChart = ({ readings, events, setBrushRange, brushRange, refreshTrigger, chartRange }: ReadingsChartProps) => {
    const yAxisFormatter = power => {
        return power.toString() + ' MW'
    }

    return <Row><div data-testid="chart-container" className="chart-container" id="chart-container">
        <Col xs={12} className="p-0">
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart key={refreshTrigger} data={readings} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                    <XAxis
                        dataKey="date"
                        tickLine={false}
                        tick={<CustomizedAxisTick readings={readings} brushRange={brushRange} />}
                        interval={0}
                    />
                    <YAxis dataKey="power" tick={{ fill: '#666', width: '75' }}
                        axisLine={{ stroke: '#666' }}
                        tickFormatter={yAxisFormatter}
                        tickLine={false}
                    />
                    <Tooltip content={CustomTooltip} />
                    <Line
                        dataKey="power"
                        stroke="#27AE60"
                        strokeWidth={2}
                        dot={false}
                    />
                    <Brush
                        dataKey="date"
                        data-testid="chart-brush"
                        startIndex={brushRange ? brushRange[0] : 0}
                        endIndex={brushRange ? brushRange[1] : readings.length - 1}
                        height={30}
                        travellerWidth={30}
                        stroke="#27AE60"
                        onChange={({ startIndex, endIndex }) => (startIndex && endIndex) ? setBrushRange([startIndex, endIndex]) : undefined}
                        tickFormatter={() => ''}
                    />
                    {events?.map((event, index) => {
                        const eventStart = event.start
                        const eventEnd = event.end
                        const range = chartRange()
                        if (range) {
                            return <ReferenceArea
                                key={index}
                                x1={(eventStart < range.start ? range.start : eventStart).toUnixInteger()}
                                x2={(eventEnd > range.end ? range.end : eventEnd).toUnixInteger()}
                                stroke="none"
                                fill="rgba(255, 165, 0, 0.5)"
                            />
                        }
                    })}
                </ComposedChart>
            </ResponsiveContainer>
        </Col>
    </div>
    </Row>
}

const NoDataAvailable = () =>
    <Card className={'text-center w-100 p-4 border'} style={{ border: '#ccc' }} data-testid="no-data-card">
        <Card.Body>
            <Card.Title>No Data Available</Card.Title>
            <Card.Text>
                There is no data available for this chart. Please select different parameters or try again later.
            </Card.Text>
        </Card.Body>
    </Card>

const EventTable = ({ readings, events, setBrushRange, setRefreshTrigger, refreshTrigger, chartRange }: EventTableProps) => {

    const handleRowClick = (event: EventWithDetails) => {
        if (readings) {
            const start = event.start.minus({ minute: 30 }).toUnixInteger()
            const end = event.end.plus({ minute: 30 }).toUnixInteger()
            let eventStartIndex = readings.findIndex(reading => reading.date >= start)
            let eventEndIndex = readings.findIndex(reading => reading.date >= end)

            const updateChart = () => {
                setBrushRange([eventStartIndex, eventEndIndex])

                const chartContainer = document.getElementById('chart-container')
                if (chartContainer) {
                    setRefreshTrigger(refreshTrigger + 1)
                    chartContainer.scrollIntoView({ behavior: 'smooth' })
                }
            }

            if (eventStartIndex == -1) {
                if (eventEndIndex != -1) {
                    eventStartIndex = 0
                    updateChart()
                }
            } else if (eventEndIndex == -1) {
                if (eventStartIndex != -1) {
                    eventEndIndex = readings.length - 1
                    updateChart()
                }
            } else {
                updateChart()
            }
        }
    }

    return <Table striped bordered hover>
        <thead>
            <tr>
                <th>Event ID</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Event Type</th>
            </tr>
        </thead>
        <tbody data-testid="event-table">
            {events.sort((a, b) => a.start.toMillis() - b.start.toMillis()).map((event: EventWithDetails, index) => {
                const range = chartRange()
                const isClickable = range && (range.contains(event.start) || range.contains(event.end))
                return <tr data-testid={`event-row-${index}`} key={index} style={{ cursor: isClickable ? 'pointer' : 'default' }} onClick={() => isClickable && handleRowClick(event)}>
                    <td>{event.id}</td>
                    <td>{event.start.toFormat('MMM dd HH:mm a')}</td>
                    <td>{event.end.toFormat('MMM dd HH:mm a')}</td>
                    <td>{event.eventType.displayName}</td>
                </tr>
            })}
        </tbody>
    </Table>
}

export const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload
        const date = DateTime.fromSeconds(data.date).toLondonTimeZone().toFormat('MMM dd HH:mm a')
        return (
            <div data-testid="custom-tooltip" className="custom-tooltip">
                <p>Time: {date}</p>
                <p>Power: {data.power} MW</p>
            </div>
        )
    }
    return null
}


export type TickProps = {
    x?: number
    y?: number
    payload?: any
    readings?: PowerEntry[]
    brushRange?: [number, number]
}

export const CustomizedAxisTick: React.FC<TickProps> = ({ x, y, payload, readings, brushRange }) => {
    if (readings && readings.length > 0 && brushRange) {
        const value = payload.value
        const date = DateTime.fromSeconds(value).toLondonTimeZone()
        const minutes = date.minute
        const hours = date.hour
        const seconds = date.second

        if (seconds != 0) return null

        const label = () => <svg><text data-testid="x-tick" x={x} y={y} dy={16} textAnchor="middle" fill="#666">{date.toFormat("hh:mm a")}</text></svg>
        const range = readings[brushRange[1]].date - readings[brushRange[0]].date

        if (range / 60 < 6) {
            return label()
        }
        else if (range / 60 < 15) {
            return minutes % 2 === 0 ? label() : null
        }
        else if (range / 60 < 30) {
            return minutes % 5 === 0 ? label() : null
        } else if (range / 60 < 90) {
            return minutes % 15 === 0 ? label() : null
        } else if (range / 60 / 60 < 3) {
            return minutes % 30 === 0 ? label() : null
        } else if (range / 60 / 60 < 6) {
            return minutes === 0 ? label() : null
        } else if (range / 60 / 60 < 12) {
            return minutes === 0 && hours % 2 === 0 ? label() : null
        } else if (range / 60 / 60 < 18) {
            return minutes === 0 && hours % 3 === 0 ? label() : null
        } else {
            return minutes === 0 && hours % 4 === 0 ? label() : null
        }

    } else return null
}
